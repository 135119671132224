
import gptModule from '@/store/modules/gptModule';
import { getComponent } from '@/utils/helpers';
import { Component, Vue } from 'vue-property-decorator';
import FileUpload from '@/components/tasqsCommon/FileUpload.vue';

@Component({
  components: {
    ValueColumn: () => getComponent('gpt/ValueColumn'),
    FileUpload
  },
})
export default class TankDetail extends Vue {
  rowData: any = [];

  dataLoading = false;

  values: number[] = [0, 0, 0, 0, 0];// Initial values for each column

  updateValue(index: number, value: number) {
    this.values.splice(index, 1, value);
  }

  submitValue() {
    console.log('Selected value:', this.values.join(''));
    // Emit an event or do something with the selected value
  }

  headers = [
    'Last Event Date & Time',
    'Meas. level',
    'last (+/-)',
    'Run ticket',
    'bbls',
    'oil/water level',
  ];

  formatDateAndTime(date) {
    const optionsDate: any = {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
    };

    const optionsTime: any = {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
    };

    const formattedDate = new Intl.DateTimeFormat('en-US', optionsDate).format(
      date
    );
    const formattedTime = new Intl.DateTimeFormat('en-US', optionsTime).format(
      date
    );

    return {
      date: formattedDate.replace(/,/g, ''),
      time: formattedTime.replace(/,/g, ''),
    };
  }

  get tanks() {
    return [
      {
        last_guage_datetime: new Date(),
        measurement_level: '3200',
        last_change: '24-01-10',
        last_ticket_image: 'RT005',
        bbls: '1500',
        level: '7 ft 2 in',
      },
      {
        last_guage_datetime: new Date(),
        measurement_level: '3200',
        last_change: '24-01-10',
        last_ticket_image: 'RT005',
        bbls: '1500',
        level: '7 ft 2 in',
      },
      {
        last_guage_datetime: new Date(),
        measurement_level: '3200',
        last_change: '24-01-10',
        last_ticket_image: 'RT005',
        bbls: '1500',
        level: '7 ft 2 in',
      },
      {
        last_guage_datetime: new Date(),
        measurement_level: '3200',
        last_change: '24-01-10',
        last_ticket_image: 'RT005',
        bbls: '1500',
        level: '7 ft 2 in',
      },
      {
        last_guage_datetime: new Date(),
        measurement_level: '3200',
        last_change: '24-01-10',
        last_ticket_image: 'RT005',
        bbls: '1500',
        level: '7 ft 2 in',
      },
      {
        last_guage_datetime: new Date(),
        measurement_level: '3200',
        last_change: '24-01-10',
        last_ticket_image: 'RT005',
        bbls: '1500',
        level: '7 ft 2 in',
      },
      {
        last_guage_datetime: new Date(),
        measurement_level: '3200',
        last_change: '24-01-10',
        last_ticket_image: 'RT005',
        bbls: '1500',
        level: '7 ft 2 in',
      },
    ];
  }

  async created() {
    this.dataLoading = true;
    this.dataLoading = false;
  }
}
